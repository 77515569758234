import React from 'react';
import { isMobile } from 'react-device-detect'
import axios from 'axios'

import logo from './logo.png';
import './App.css';

class App extends React.Component {
  state = {
    screenMode: 'normal', // 'normal' or 'splash'
    countryCode: null
  }

  componentDidMount() {
    this.getGeoInfo()
  }

  openIOS = () => {
    window.location.assign('https://apps.apple.com/us/app/mungbean/id1481862098')
  }

  openAndroid = () => {
    window.location.assign('https://play.google.com/store/apps/details?id=com.mungbeanlabs.atfchat')
  }

  handleScreenModeChange = (event) => {
    this.setState({ screenMode: event.target.value })
  }

  renderBottomToolbarSeparator() {
    return (
      <div className='bottom-toolbar-separator'>•</div>
    )
  }

  getGeoInfo = async () => {
    let response = await axios.get('https://ipapi.co/json/')
    let data = response.data
    this.setState({ countryCode: data.country_code })
  }

  renderUnsupportedCountry() {
    let supportedCountryCodes = ['US', 'CA', 'SG', 'NZ', 'AU']
    let countries = supportedCountryCodes[0]
    for (let i = 1; i < supportedCountryCodes.length - 1; i++) {
      countries = countries + ', ' + supportedCountryCodes[i]
    }
    countries = countries + ' and ' + supportedCountryCodes[supportedCountryCodes.length - 1]

    if (this.state.countryCode !== null && !supportedCountryCodes.includes(this.state.countryCode)) {
      return (
        <p id="unsupported-country-label">{`(Available in ${countries} app stores. More countries will be added soon!)`}</p>
      )
    }
  }

  render() {
    let hideIfMobile = isMobile ? 'none' : 'inline'
    let appInfoFlexDirection = isMobile ? 'column' : 'row'
    let appInfoWidth = isMobile ? '70vw' : '20vw'
    let logoHeight = isMobile ? '30vmin' : '16vmin'
    let hideIfSplash = this.state.screenMode === 'splash' ? 'none' : 'inline'
    let hideIfMobileOrSplash = (isMobile || (this.state.screenMode === 'splash')) ? 'none' : 'inline'
    let bottomToolbarFontSize = isMobile ? '9pt' : '11pt'

    return (
      <div className='App'>
        <div className='content'>

          <select id='mode-dropdown-button' style={{ display: hideIfMobile }} value={this.state.screenMode} onChange={this.handleScreenModeChange}>
            <option value='normal'>normal</option>
            <option value='splash'>splash</option>
            {/* TODO: bouncing DVD logo mode */}
          </select>

          <div className='app-info' style={{ flexDirection: appInfoFlexDirection }}>
            {/* app-info is has a left and right app info container */}
            <div id='app-logo-container' >
              <img className='app-logo' style={{ height: logoHeight }} src={logo} alt='logo' />
            </div>

            <div id='app-info-separator' style={{ display: hideIfMobileOrSplash }} />

            <div id='app-description-container' style={{ width: appInfoWidth, display: hideIfSplash }} >
              <p>mungbean - the local group chat where everyone's invited. Post memes, collect upvotes, and more.</p>

              <h2>Get it now on iOS and Android!</h2>
              {/* {this.renderUnsupportedCountry()} */}
              <div id='download-buttons-container'>
                <button className='download-button' onClick={this.openIOS} >iOS</button>
                <button className='download-button' onClick={this.openAndroid}>Android</button>
              </div>
            </div>
          </div>

          {/* Use Chrome Inspect Element to show and edit the title. i.e. { display: inline } */}
          <h1 id='title-bar' style={{ fontSize: '40pt', display: 'none' }}> Edit Me </h1>

          <div className='bottom-toolbar' style={{ fontSize: bottomToolbarFontSize, display: hideIfSplash }}>
            <a href='https://docs.mungbean.io/terms-of-service'>Terms of Service</a>
            {this.renderBottomToolbarSeparator()}
            <a href='https://docs.mungbean.io/privacy-policy'>Privacy Policy</a>
            {this.renderBottomToolbarSeparator()}
            <a href='mailto:support@mungbean.io'>support@mungbean.io</a>
            {this.renderBottomToolbarSeparator()}
            © 2023 Mungbean Labs, LLC
          </div>
        </div>
      </div>
    );
  }
}

export default App;
